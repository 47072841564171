// extracted by mini-css-extract-plugin
export var Job = "style-module--Job--d7ed7";
export var actions = "style-module--actions--78f1f";
export var active = "style-module--active--817e3";
export var blockImg = "style-module--block-img--09d9f";
export var ctaMixin = "style-module--ctaMixin--dd523";
export var flex = "style-module--flex--a8829";
export var illustration = "style-module--illustration--bf2ac";
export var introduction = "style-module--introduction--4a607";
export var jobInfo = "style-module--jobInfo--172be";
export var marginLeft = "style-module--marginLeft--0b8ee";
export var share = "style-module--share--c12e9";
export var tagMixin = "style-module--tagMixin--0b23a";
export var title = "style-module--title--ab6d1";
export var typeBodyL = "style-module--typeBodyL--33a1e";
export var typeBodyM = "style-module--typeBodyM--21d62";
export var typeBodyXl = "style-module--typeBodyXl--60378";
export var typeCaption = "style-module--typeCaption--d123e";
export var typeCta = "style-module--typeCta--10d15";
export var typeH4 = "style-module--typeH4--0fde8";
export var typeH5 = "style-module--typeH5--c64cd";
export var typeHeadlineL = "style-module--typeHeadlineL--6a395";
export var typeHeadlineM = "style-module--typeHeadlineM--ddbdb";
export var typeHeadlineXL = "style-module--typeHeadlineXL--c9369";
export var typeHeadlineXXL = "style-module--typeHeadlineXXL--2038b";
export var typeMedium = "style-module--typeMedium--b1b27";
export var typeMenu = "style-module--typeMenu--fd4f2";
export var typeTag = "style-module--typeTag--e871c";
export var typeheadlineXsEmphasised = "style-module--typeheadlineXsEmphasised--be4a7";