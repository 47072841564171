import { ApplicationForm } from '~globals/ApplicationForm'
import { SpecialTeaser } from '~globals/SpecialTeaser'
import { Teaser } from '~globals/Teaser'
import { Seo } from '~globals/Seo'
import { TextSection } from '~components/content/TextSection'
import * as styles from './style.module.scss'
import Container from '~globals/Container'
import Cta from '~globals/Cta'
import Loca from '~images/icons/Icon-Pin.svg'
import Clock from '~images/icons/Icon-Time.svg'
import parse from 'html-react-parser'
import React, { useEffect, useState, useRef } from 'react'
import uniqid from 'uniqid'
// import { jsPDF } from 'jspdf'
// import html2canvas from 'html2canvas'
import { useReactToPrint } from 'react-to-print'

const Page = ({
  pageContext: {
    page: { data },
    applicationForm,
    global,
    specialTeasers,
    teasers,
  },
}) => {
  const {
    category,
    contract,
    description,
    extra_content,
    gender,
    introduction,
    location,
    share,
    title,
  } = data
  const [isMobile, setIsMobile] = useState(false)
  const printRef = useRef(null)

  const { job_application_cta } = global.data

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true)
    }
  }, [])

  const url = typeof window !== 'undefined' ? window.location.href : ''
  // const pathname = typeof window !== 'undefined' ? window.location.pathname : ''

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  })

  const printDocument = e => {
    e.preventDefault()
    // const input = document.getElementById('toPrint')
    // html2canvas(input)
    //   .then((canvas) => {
    //     const imgData = canvas.toDataURL('image/png')
    //     const pdf = new jsPDF()
    //     pdf.addImage(imgData, 'JPEG', 0, 0)
    //     // pdf.output('dataurlnewwindow');
    //     pdf.save("download.pdf")
    //   })
    handlePrint()
  }

  return (
    <>
      <div ref={printRef} data-html className={styles.Job}>
        <Container>
          <div className={styles.flex}>
            <div className={styles.marginLeft}>
              <div data-appear className={styles.title}>
                {parse(title.html)}
                {gender}
              </div>
              <div className={styles.jobInfo}>
                <p>
                  <img src={Loca} alt="Location pin" />
                  {location.document.data.title.text}
                </p>
                <p>
                  <img src={Clock} alt="Clock" />
                  {contract.document.data.type}
                </p>
              </div>
            </div>
            <div data-appear className={styles.illustration}>
              <img src={category.document.data.icon.url} alt={category.document.data.icon.url} />
            </div>
          </div>

          {isMobile && (
            <div className={styles.share}>
              <p>{share}</p>
              <a onClick={printDocument} href="/">
                <img src="/icons/pdf.svg" alt="PDF / Print" />
              </a>
              <a
                href={`mailto:?subject=Shiso Burger ${title.text} - ${location.document.data.title.text}&body=${encodeURIComponent(url)}`}
                target="_blank"
                rel="noreferrer"
              >
                <img src="/icons/mail.svg" alt="Mail" />
              </a>
            </div>
          )}

          <div className={styles.actions}>
            <Cta
              anchor="application-form"
              text={job_application_cta.text}
            />
            {!isMobile && (
              <div className={styles.share}>
                <p>{share}</p>
                <a onClick={printDocument} href="/">
                  <img src="/icons/pdf.svg" alt="PDF / Print" />
                </a>
                <a
                  href={`mailto:?subject=Shiso Burger ${title.text} - ${location.document.data.title.text}&body=${encodeURIComponent(url)}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/icons/mail.svg" alt="Mail" />
                </a>
              </div>
            )}
          </div>

          <div className={styles.flex}>
            <div className={styles.introduction}>
              <TextSection
                content={introduction}
              />
            </div>
          </div>
          {description?.document?.data && (
            <div>
              <TextSection content={description.document.data} />
            </div>
          )}
        </Container>
      </div>

      <Container>
        <ApplicationForm
          data={applicationForm.data}
          location={location.document.data.title.text}
          jobTitle={title.text}
        />
        {extra_content.length !== 0 && extra_content.map(({ element }) => {
          let sectionEl
          switch (element.type) {
            case 'teaser':
              const teaser = teasers.filter(item => item.prismicId === element.id)
              sectionEl = (
                <Teaser
                  content={teaser}
                  key={uniqid()}
                />
              )
              break;
            case 'special_teaser':
              const specialTeaser = specialTeasers.filter(item => item.prismicId === element.id)
              sectionEl = (
                <SpecialTeaser
                  content={specialTeaser}
                  key={uniqid()}
                />
              )
              break;
              default:
                sectionEl = null
            }
          return sectionEl
        })}
      </Container>
    </>
  )
}

export default Page

export const Head = ({
  pageContext: {
    page: { data },
  },
}) => {
  const { category, location, title } = data

  return (
    <Seo
      sharingTitle={`${title.text} | Shiso Burger`}
      sharingDescription={location.document.data.title.text}
      sharingImage={category.document.data.icon.url}
    />
  )
}

